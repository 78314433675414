import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Image from "../components/tacticalImg"
import Cta from "../components/cta"
import CtaBtn from "../components/ctabtn"
import Modal from "react-modal"

import DiplomaIcon from "../images/diploma.svg"
import Switzerland from "../images/switzerland-flag.svg"
import Portugal from "../images/portugal-flag.svg"
import Spain from "../images/spain-flag.svg"

const Bio = styled.div`
  @media (max-width: 640px) {
    h2 {
      margin-top: var(--medium);
    }
  }
`

const Diploma = styled.div`
  position: relative;
  background: var(--soft);
  border-radius: 2px 2px 50px 2px;
  margin-bottom: var(--large);
  .icon {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    background: var(--heading);
    position: absolute;
    top: -1rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%) translate;
    svg {
      margin: 0 auto;
    }
  }
  .title {
    padding-top: 4rem;
    padding-bottom: var(--medium);
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;
    margin: 0 auto;
    max-width: 30ch;
  }
  .place {
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: var(--medium);
  }
  .year {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;
    padding-bottom: var(--small);
  }
  .country {
    text-align: center;
    padding-bottom: var(--medium);
  }
  @media (max-width: 768px) {
    padding: var(--small) var(--medium);
  }
`

const DiplomaGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  padding-bottom: var(--medium);
  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 768px) {
    padding-top: var(--xl2);
  }
`

const DiplomaGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  margin: var(--xl) 0 var(--xl2);
  img {
    border: 1px solid var(--button);
  }
  button {
    cursor: pointer;
    background: none;
    border: none;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "2px solid var(--button)",
  },
}

const ModalStyling = styled.div`
  width: 500px;
  @media (max-width: 640px) {
    width: 260px;
    max-width: 100%;
  }
`

const About = ({ setShowCta, cta }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [imageToShow, setImageToShow] = useState("")
  useEffect(() => {
    Modal.setAppElement("body")
  }, [])
  const diplomas = []
  for (let index = 1; index < 8; index++) {
    let object = {}
    object.alt = `Diplome ${index}`
    object.src = `Diplomes${index}.jpg`
    diplomas.push(object)
  }
  const openModal = x => {
    setImageToShow(x)
    setModalIsOpen(true)
  }
  const closeModal = () => setModalIsOpen(false)
  return (
    <Layout>
      <SEO title="À propos de Elsa" />
      <Hero title="À propos de Elsa" hero="a-propos.jpg" />
      <div className="container grid highlow">
        <div className="col-md-5 vertical-center">
          <Image src="elsa-almeida.jpg" alt="Elsa Almeida" />
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-6">
          <Bio className="vertical-center">
            <h2 style={{ textAlign: "left" }}>Elsa Almeida</h2>
            <p>
              Massothérapeute agréée ASCA,{" "}
              <strong>RCC I573763, ID-ASCA 103023</strong>
              <br />
              Fondation Suisse pour les médecines complémentaires
            </p>
            <p>
              Esthéticienne et cosmétologue, diplômée au Portugal et au niveau
              Européen. Diplôme N°111/2012
            </p>
            <p>
              Passionnée par ces domaines, les formations suivies m’ont permis
              d’acquérir un savoir-faire afin de répondre à vos besoins.
            </p>
            <p>
              J’ai approché la massothérapie comme outil de bien-être pour la
              relaxation, bien sûr, mais aussi pour tout le volet thérapeutique.
              Après avoir découvert et expérimenté diffé-rentes thérapies
              manuelles, j’ai compris alors qu’un massage peut être un excellent
              complément aux médecines dites traditionnelles, autant pour les
              sportifs que pour ceux qui souffrent de douleurs chroniques.
            </p>
            <p>
              Je vise à apporter du bien-être et de l'harmonie non seulement au
              corps, mais aussi à l'âme et à l'esprit.
            </p>
            <p>
              Mon objectif au quotidien est d’offrir un massage personnalisé,
              autant thérapeutique que de détente, à chacun de mes clients.
              Chaque personne est différente et donc requiert une approche
              différente en fonction du travail, des sports et loisirs
              pratiqués.
            </p>
            <p>
              Je me réjouis de vous accueillir dans mon espace de massothérapie
              et de bien-être.
            </p>
            <div style={{ paddingTop: "var(--small)" }}>
              <CtaBtn setShowCta={setShowCta} cta={cta} />
            </div>
          </Bio>
        </div>
        <div className="col-md-1"></div>
      </div>
      <div className="container side-padding">
        <DiplomaGrid>
          <Diploma>
            <div className="icon vertical-center">
              <img src={DiplomaIcon} alt="Diploma Icon" />
            </div>
            <div className="title">
              Diplôme de massage thérapeutique, attestation de médecine
              empirique
            </div>
            <div className="place">École technique de Santé à Écublens.</div>
            <div className="year">2020</div>
            <div className="country">
              <img src={Switzerland} alt="Switzerland Flag" />
            </div>
          </Diploma>
          <Diploma>
            <div className="icon vertical-center">
              <img src={DiplomaIcon} alt="Diploma Icon" />
            </div>
            <div className="title">
              Aggrégation ASCA, RCC I573763, ID-ASCA 103023
            </div>
            <div className="place">
              Fondation Suisse pour les médecines complémentaires
            </div>
            <div className="year">2019</div>
            <div className="country">
              <img src={Switzerland} alt="Switzerland Flag" />
            </div>
          </Diploma>
          <Diploma>
            <div className="icon vertical-center">
              <img src={DiplomaIcon} alt="Diploma Icon" />
            </div>
            <div className="title">
              Diplôme de Anatomie, physiologie et pathologies
            </div>
            <div className="place">École technique de Santé à Écublens.</div>
            <div className="year">2019</div>
            <div className="country">
              <img src={Switzerland} alt="Switzerland Flag" />
            </div>
          </Diploma>
          <Diploma>
            <div className="icon vertical-center">
              <img src={DiplomaIcon} alt="Diploma Icon" />
            </div>
            <div className="title">Diplôme de massage classique</div>
            <div className="place">École technique de Santé à Écublens.</div>
            <div className="year">2019</div>
            <div className="country">
              <img src={Switzerland} alt="Switzerland Flag" />
            </div>
          </Diploma>
          <Diploma>
            <div className="icon vertical-center">
              <img src={DiplomaIcon} alt="Diploma Icon" />
            </div>
            <div className="title">
              Attestation mindfulness, hypnose et relaxation EDUCH.CH
            </div>
            <div className="place">
              Fédération suisse pour la formation continue
            </div>
            <div className="year">2019</div>
            <div className="country">
              <img src={Switzerland} alt="Switzerland Flag" />
            </div>
          </Diploma>
          <Diploma>
            <div className="icon vertical-center">
              <img src={DiplomaIcon} alt="Diploma Icon" />
            </div>
            <div className="title">
              Diplôme N°.111/2012 de Esthéticienne et cosmétologue
            </div>
            <div className="place">Centre de formation professionnelle </div>
            <div className="year">2011/2</div>
            <div className="country">
              <img src={Portugal} alt="Portugal Flag" />
            </div>
          </Diploma>
          <Diploma>
            <div className="icon vertical-center">
              <img src={DiplomaIcon} alt="Diploma Icon" />
            </div>
            <div className="title">
              Diplôme de Techniciens auxiliaires de physiothérapie et
              massothérapie Certificat n°331/2010, I.T.M.
            </div>
            <div className="place">Institut thérapeutiques manuelles</div>
            <div className="year">2009/2010</div>
            <div className="country">
              <img src={Portugal} alt="Portugal Flag" />
            </div>
          </Diploma>
          <Diploma>
            <div className="icon vertical-center">
              <img src={DiplomaIcon} alt="Diploma Icon" />
            </div>
            <div className="title">
              Formation 1er niveau de Reiki (thérapies manuelles des énergies)
            </div>
            <div className="place">École Sorisa</div>
            <div className="year">2007</div>
            <div className="country">
              <img src={Portugal} alt="Portugal Flag" />
            </div>
          </Diploma>
          <Diploma>
            <div className="icon vertical-center">
              <img src={DiplomaIcon} alt="Diploma Icon" />
            </div>
            <div className="title">
              Formation de massage géothermal (pierres chaudes)
            </div>
            <div className="place">École Sorisa</div>
            <div className="year">2006</div>
            <div className="country">
              <img src={Portugal} alt="Portugal Flag" />
            </div>
          </Diploma>
          <Diploma>
            <div className="icon vertical-center">
              <img src={DiplomaIcon} alt="Diploma Icon" />
            </div>
            <div className="title">Diplôme de épilation « DEPÍLOK »,</div>
            <div className="place">Bodigar Cosmetc,S.L. blens.</div>
            <div className="year">2005</div>
            <div className="country">
              <img src={Spain} alt="Spain Flag" />
            </div>
          </Diploma>
          <Diploma>
            <div className="icon vertical-center">
              <img src={DiplomaIcon} alt="Diploma Icon" />
            </div>
            <div className="title">
              Cours de perfectionnement : traitement facial et corporel
            </div>
            <div className="place">école SHEILA</div>
            <div className="year">2004/2005</div>
            <div className="country">
              <img src={Spain} alt="Spain Flag" />
            </div>
          </Diploma>
          <Diploma>
            <div className="icon vertical-center">
              <img src={DiplomaIcon} alt="Diploma Icon" />
            </div>
            <div className="title">Formation de massage de relaxation</div>
            <div className="place">école Sorisa</div>
            <div className="year">2003/2004</div>
            <div className="country">
              <img src={Portugal} alt="Portugal Flag" />
            </div>
          </Diploma>
        </DiplomaGrid>
        <DiplomaGallery>
          {diplomas.map((x, index) => (
            <button
              onClick={e => {
                openModal(x)
              }}
              key={index}
            >
              <Image src={x.src} alt={x.alt} />
            </button>
          ))}
        </DiplomaGallery>
        <Modal
          id="modal"
          isOpen={modalIsOpen}
          style={customStyles}
          contentLabel="Diploma Modal"
          onRequestClose={closeModal}
        >
          <ModalStyling id="modal">
            <div style={{ textAlign: "right" }}>
              <button
                onClick={closeModal}
                style={{
                  cursor: "pointer",
                  padding: "1rem 0",
                  background: "none",
                  border: "none",
                  zIndex: 30,
                }}
              >
                <svg
                  role="button"
                  width="16"
                  height="16"
                  viewBox="0 0 9 9"
                  fill="#000"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.48438 3.32812L6.09375 0.546875H8.20312L5.61719 4.71094L8.28906 9H6.19531L4.50781 6.10938L2.82812 9H0.71875L3.39062 4.71094L0.8125 0.546875H2.90625L4.48438 3.32812Z"
                    fill="#000"
                  />
                </svg>
              </button>
            </div>
            <Image src={imageToShow.src} alt={imageToShow.alt} />
          </ModalStyling>
        </Modal>
      </div>
      <Cta setShowCta={setShowCta} cta={cta} />
    </Layout>
  )
}

export default About
